import React from 'react';
import {graphql, Link} from "gatsby";
import Layout from "../../Common/Container";
import {Breadcrumbs, CardContent, Chip, Container, Grid, Paper} from "@material-ui/core";
import ReactPlayer from "react-player";
import {makeStyles} from "@material-ui/core/styles";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Img from "gatsby-image";
import HomeIcon from "@material-ui/icons/Home";


const useStyles = makeStyles(({palette, ...theme}) => ({
  card: {
    boxShadow: "12px 0 10px -2px #f7f7f7, -12px 0 10px -2px #f7f7f7",
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    marginTop: '2px',
    width: 20,
    height: 20,
  },
  root: {
    position: "sticky",
    top: "4rem",
    minWidth: "175"
  },
}));

const VideoPlayer = ({data}) => {
  const classes = useStyles();
  const {videosJson} = data;
  const {edges} = data.allVideosJson;
  return (
    <Layout>
      <section className="section pt-20">
        <Container maxWidth="lg">
          <div className="landing">
            <div className="pt-10">
              <Breadcrumbs aria-label="breadcrumb" className="mb-3">
                <Link color="inherit" href="/" className={classes.link}>
                  <HomeIcon className={classes.icon}/> Home
                </Link>
                <Link color="inherit" href={`/videos/${videosJson.category}`} className={classes.link}>
                  {videosJson.category}
                </Link>
                <p style={{lineHeight: '1', color: "#000"}}>{videosJson.title}</p>
              </Breadcrumbs>
            </div>
          </div>
          <div className="py-10 pt-10">
            <Grid container spacing={6}>
              <Grid item lg={9}>
                <ReactPlayer
                  url={videosJson.videoUrl}
                  width="100%"
                  height="580px"
                  fluid={videosJson.image.src.childImageSharp.fluid}
                  playing
                  controls
                  playIcon={<PlayCircleOutlineIcon className="text-72" color="#484848">Play</PlayCircleOutlineIcon>}
                  className={classes.card}
                />
              </Grid>

              <Grid item lg={3}>
                <Paper
                  className={classes.root}
                  style={{paddingTop: '0px', boxShadow: 'none', maxHeight: "570px", overflow: 'scroll'}}
                >
                  {edges.map((item) => {
                    return (
                      <Link to={`/videos/${item.node.category}/${item.node.slug}`}>
                        <Img fluid={item.node.image.src.childImageSharp.fluid} className="max-w-450"/>
                        <h3 className="text-18 mb-10 mt-1 font-semibold">{item.node.title}</h3>
                      </Link>
                    )
                  })}
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Container>
      </section>
    </Layout>
  );
};
export default VideoPlayer;
export const query = graphql`
    query VideoPlayer($slug: String, $category: String) {
        videosJson(slug: {eq:  $slug}) {
            videoUrl
            title
            image {
                src {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            description
            category
            slug
        }
        allVideosJson(filter: {category: {eq: $category}}) {
            edges {
                node {
                    id
                    title
                    videoUrl
                    slug
                    description
                    category
                    image {
                        src {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
